import {
  PerspectiveCamera,
  Scene,
  Group,
  CameraHelper,
  Vector2,
  Mesh,
  SphereGeometry,
  MeshBasicMaterial,
  WebGLRenderer,
} from 'three'
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import DebugPlane from './DebugPlane'

@Component
export default class Helpers extends Vue {
  @Prop()
  bounding!: any

  @Inject()
  renderer!: WebGLRenderer

  @Inject()
  camera!: PerspectiveCamera

  @Inject()
  devCamera!: PerspectiveCamera

  @Inject()
  orbit!: OrbitControls

  @Inject()
  scene!: Scene

  root = new Group()

  cameraHelper = new CameraHelper(this.camera)

  transformControls = new TransformControls(this.devCamera, this.renderer.domElement)

  boundingPlane = new DebugPlane()
  viewportPlane = new DebugPlane()

  hotspotHelper = new Mesh(new SphereGeometry(0.0002, 8, 8), new MeshBasicMaterial({ color: 'red', wireframe: true }))

  @Watch('bounding.screen', { deep: true, immediate: true })
  resizeViewPortPlane({ width, height }: Vector2) {
    this.viewportPlane.setSize(width, height)
  }

  @Watch('bounding.scene', { deep: true, immediate: true })
  resizeBoundingPlane({ width, height }: Vector2) {
    this.boundingPlane.setSize(width, height)
  }

  mounted() {
    this.boundingPlane.position.set(0, 0, this.bounding.depth)
    this.viewportPlane.position.set(0, 0, this.bounding.depth)

    this.transformControls.attach(this.hotspotHelper)
    this.transformControls.addEventListener('change', () =>
      console.log('TransformControls:update', this.transformControls.object?.position)
    )
    this.transformControls.addEventListener('dragging-changed', (event) => (this.orbit.enabled = !event.value))

    this.root.add(this.transformControls)
    this.root.add(this.viewportPlane)
    this.root.add(this.boundingPlane)
    this.root.add(this.cameraHelper)
    this.root.add(this.hotspotHelper)

    this.scene.add(this.root)
  }

  destroyed() {
    this.scene.remove(this.root)

    this.root.remove(this.hotspotHelper)
    this.root.remove(this.cameraHelper)
    this.root.remove(this.boundingPlane)
    this.root.remove(this.viewportPlane)
    this.root.remove(this.transformControls)

    this.transformControls.detach()
    this.transformControls.dispose()

    this.hotspotHelper.geometry.dispose()
    this.hotspotHelper.material.dispose()

    this.boundingPlane.dispose()
    this.viewportPlane.dispose()
  }

  render() {
    return null
  }
}
