
  import { SettingState } from '@/store/types'
  import { Events, Scenes } from '@/constants'
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import * as dat from 'dat.gui'
  import { Action } from 'vuex-class'

  @Component
  export default class Gui extends Vue {
    @Prop()
    settings!: SettingState

    @Action('setScene')
    setScene!: (scene: string) => void

    setup() {
      this.addFolder(this.$gui.addFolder('lights'), this.settings.lights)
      this.addFolder(this.$gui.addFolder('clouds'), this.settings.clouds)
      this.addFolder(this.$gui.addFolder('water'), this.settings.water)
      this.addFolder(this.$gui.addFolder('sprinkles'), this.settings.sprinkles)
      this.addFolder(this.$gui.addFolder('door'), this.settings.door)
      this.addFolder(this.$gui.addFolder('glow'), this.settings.glow)
      this.addFolder(this.$gui.addFolder('portal'), this.settings.portal)
      this.addFolder(this.$gui.addFolder('flowers'), this.settings.flowers)
      this.addFolder(this.$gui.addFolder('bloom'), this.settings.bloom)
      //this.addFolder(this.$gui.addFolder('fluid'), this.settings.fluid)
      this.addFolder(this.$gui.addFolder('noise'), this.settings.noise)
      //this.addFolder(this.$gui.addFolder('blur'), this.settings.blur)
      this.addSelect(this.$gui.addFolder('scenes'))
    }

    addSelect(gui: dat.GUI) {
      gui
        .add({ current: '' }, 'current', {
          bedroom: Scenes.BEDROOM,
          garden: Scenes.MAKEUP,
          porch: Scenes.PORCH,
        })
        .onChange((scene) => {
          this.setScene(scene)
        })
    }

    addFolder(gui: dat.GUI, state: any) {
      let folder, row
      for (const key in state) {
        const prop = state[key]
        folder = gui
        if (prop.value === undefined) {
          folder = gui.addFolder(key)
          for (const name in prop) {
            row = prop[name]
            this.addRow(folder, name, row)
          }
        } else {
          this.addRow(folder, key, prop)
        }
      }
    }

    addRow(folder: dat.GUI, name: string, state: any) {
      const { min, max, step } = state
      const row = !isNaN(state.value)
        ? folder.add(state, 'value', min, max, step).name(name)
        : folder.addColor(state, 'value').name(name)
      row.onChange(() => this.$bus.$emit(Events.GUI.CHANGE, this.settings))
    }

    removeFolder(name: string) {
      const gui = this.$gui as any
      const folder = gui.__folders[name]
      if (!folder) return
      folder.close()
      gui.__ul.removeChild(folder.domElement.parentNode)
      delete gui.__folders[name]
      gui.onResize()
    }

    mounted() {
      this.$el.appendChild(this.$gui.domElement)

      this.setup()
    }

    destroyed() {
      this.$el.removeChild(this.$gui.domElement)

      this.removeFolder('lights')
      this.removeFolder('clouds')
      this.removeFolder('water')
      this.removeFolder('sprinkles')
      this.removeFolder('door')
      this.removeFolder('glow')
      this.removeFolder('portal')
      this.removeFolder('flowers')
      this.removeFolder('bloom')
      //this.removeFolder('fluid')
      this.removeFolder('noise')
      //this.removeFolder('blur')
      this.removeFolder('scenes')
    }
  }
